#root {
	display: grid;
	height: 100vh;
	place-items: center;
}

html,
body {
	height: 100vh;
	margin: 0;
	padding: 0;
}

body {
	background-color: #e7efe9;
	color: #2b3b3b;
	font-family: 'Roboto', sans-serif;
	font-size: 0.9em;
}

a {
	color: #e77125;
	text-decoration: inherit;
}

a:hover {
	text-decoration: underline;
}
